import Button from '@atlaskit/button';
import { Field } from '@atlaskit/form';

import Textfield from '@atlaskit/textfield';

import api from '~/services/api';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
  KeyboardEvent,
} from 'react';

import { FaRegWindowRestore } from 'react-icons/fa';

import { Span } from '../Span';
import { AlertBox } from './styles';
import { ModalContabil } from './Components/Modal';

interface FormProps {
  value: string | number | undefined;
  isRequired?: boolean;
  min?: number;
  max?: number;
  maxLength?: number;
  isUndefined?: boolean;
  isNull?: boolean;
  onChange(data: any, data1: boolean, codConta: number): any;
  setInvalid?: boolean;
  iniInicializado?: boolean;
  isDisabled?: boolean;
  autoFocus?: boolean;
  label?: string;
  lengthBuscaConta?: number;
}
type Conta = {
  cod_conta: number;
  conta_contabil: string;
  conta_reduzida: string;
  des_conta_contabil: string;
};

/**
 * @function onChange Função que atualiza o valor do input
 * @returns Componente para validação de inputs
 * @value Valor correspondente ao cod_conta
 */
const ContaContabil: React.FC<FormProps> = (props) => {
  const {
    value,
    isRequired = false,
    onChange,
    min = 100,
    max = 999,
    lengthBuscaConta = 3,
    maxLength = 3,
    isUndefined = false,
    isNull = null,
    setInvalid = false,
    iniInicializado = false,
    isDisabled = false,
    label = 'Conta Contábil',
  } = props;

  const [inicializado, setInicializado] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [mensagemBusca, setMensagemBusca] = useState<string>('');
  const [valueInput, setValueInput] = useState<any>(0);

  const [conta, setConta] = useState<Conta>({
    cod_conta: 0,
    conta_contabil: '',
    conta_reduzida: '',
    des_conta_contabil: '',
  });

  useEffect(() => {
    if (!Number.isNaN(value)) {
      if (value === 0) {
        setConta({
          cod_conta: 0,
          conta_contabil: '',
          conta_reduzida: '',
          des_conta_contabil: '',
        });
      }

      if (value === '') {
        setValueInput('');
        setConta({
          cod_conta: 0,
          conta_contabil: '',
          conta_reduzida: '',
          des_conta_contabil: '',
        });
      }

      if (value !== valueInput) {
        setTimeout(() => {
          setValueInput(value);
        }, 1000);
      }
    }
  }, [value]);

  const validation = useCallback(
    (newValue: string) => {
      if (inicializado && isRequired) {
        if (isUndefined && newValue === undefined) {
          setIsInvalid(true);
          onChange(newValue, true, 0);
          return;
        }
        if (isNull && newValue === null) {
          setIsInvalid(true);
          onChange(newValue, true, 0);
          return;
        }
      }
      if (inicializado) {
        if (Number(newValue) < min) {
          setIsInvalid(true);
          onChange(newValue, true, 0);
          return;
        }
        if (Number(newValue) > max) {
          setIsInvalid(true);
          onChange(newValue, true, 0);
          return;
        }
      }

      if (newValue === '') {
        setConta({
          cod_conta: 0,
          conta_contabil: '',
          conta_reduzida: '',
          des_conta_contabil: '',
        });
      }

      setValueInput(newValue);
      setIsInvalid(false);
      setInicializado(true);
    },
    [
      inicializado,
      isNull,
      isRequired,
      isUndefined,
      max,
      min,
      onChange,
      setValueInput,
    ],
  );

  useEffect(() => {
    if (inicializado && isRequired) {
      setIsInvalid(setInvalid);
    }
  }, [inicializado, isRequired, setInvalid]);
  useEffect(() => {
    setInicializado(iniInicializado);
  }, [iniInicializado]);

  const handleContas = useCallback(async () => {
    if (valueInput) {
      setMensagemBusca('');
      const valueParseString = String(valueInput);
      if (valueParseString.length > lengthBuscaConta) {
        try {
          const { data } = await api.get(`/contaContabil/${valueParseString}`);
          if (data.success) {
            setConta({
              cod_conta: data.data[0].cod_conta,
              conta_contabil: data.data[0].conta_contabil,
              conta_reduzida: data.data[0].conta_reduzida,
              des_conta_contabil: data.data[0].des_conta_contabil,
            });
            onChange(valueInput, false, data.data[0].cod_conta);
          }
        } catch (error: any) {
          setConta({
            cod_conta: 0,
            conta_contabil: '',
            conta_reduzida: '',
            des_conta_contabil: '',
          });
          setMensagemBusca('Conta contábil não encontrada');
          onChange(value, true, 0);
        }
      }
    }
  }, [valueInput]);

  useEffect(() => {
    handleContas();
  }, [valueInput]);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key.toLowerCase() === 'e' || event.key.toLowerCase() === '-') {
      event.preventDefault();
    }
  };

  return (
    <>
      <ModalContabil
        showModal={showModal}
        value={value}
        setConta={setConta}
        onChange={(val: any) => {
          onChange(val, true, 0);
        }}
        setShowModal={setShowModal}
      />
      <AlertBox>
        <div>
          <Field label={label} name="contaContabil">
            {({ fieldProps }) => (
              <div className="grupo">
                <Textfield
                  {...fieldProps}
                  className={`${
                    inicializado && isInvalid ? 'alert-class' : ''
                  } `}
                  type="number"
                  min={min}
                  max={max}
                  maxLength={maxLength}
                  step="1"
                  autoFocus
                  value={valueInput}
                  placeholder="Número conta reduzida "
                  isDisabled={isDisabled}
                  onKeyDown={handleKeyDown}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    validation(e.target.value);
                  }}
                  style={{ textAlign: 'right' }}
                />
                <Button
                  style={{ background: '#2773ca' }}
                  type="button"
                  className="inputButtons"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  <FaRegWindowRestore
                    style={{ color: '#fff', marginTop: '5px' }}
                    size={15}
                  />
                </Button>
                <Span
                  className="span"
                  value={
                    conta.conta_contabil !== ''
                      ? `${conta.conta_contabil} | ${conta.des_conta_contabil}`
                      : mensagemBusca
                  }
                />
              </div>
            )}
          </Field>
        </div>
      </AlertBox>
    </>
  );
};

export default ContaContabil;
