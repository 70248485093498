import styled from 'styled-components';

export const ContainerGrid = styled.div`
  .DataGrid {
    .Mui-selected {
      background-color: #acfcbf !important;
    }
    .MuiDataGrid-selectedRowCount {
      opacity: 0;
    }
    .MuiDataGrid-columnHeaderWrapper {
      background-color: #8850bf;
    }
    .MuiDataGrid-renderingZone:before {
      background-color: red;
    }
  }
`;

export const AlertBox = styled.div`
  position: relative;
  width: auto;
  transition: 0.2s;
  .grupo {
    display: flex;
    button {
      height: auto;
      margin-left: -10px;
      border-left: none;
      border-radius: 0px 4px 4px 0px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
    input {
      padding: 10px;
    }
    .divSpan {
      white-space: nowrap;
      min-width: 350px;
      display: flex;
      align-items: center;
      padding-left: 15px;
      span {
        color: gray;
        font-weight: 500;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
          'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif;
      }
    }
  }
  .alert-class {
    border-radius: 4px;
    position: relative;
    border-bottom: 2px solid #cf1919;
    transition: 0.5s;

    p {
      position: absolute;
      right: 0;
      border: none;
      color: #cf1919;
      transition: 0.5s;
    }
  }
`;
export const FilterConta = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
`;
export const GrupButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
export const BtnGroupContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 0;
  padding-bottom: 10px;
  color: #706f6f;
  font-family: 'Jost', sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  .btn-group {
    border-radius: 0px;
    background-color: #ecedf0;
    color: rgb(37, 56, 88);
    border: none;
    font-size: 0.9rem;
    &:active {
      outline: none;
      box-shadow: none !important;
    }
    &:focus {
      outline: none;
      box-shadow: none !important;
    }
    &:hover {
      background-color: #c995fc;
      color: #fff !important;
    }
  }

  .btn-group-active {
    background-color: #8850bf;
    color: #fff !important;

    &:hover {
      background-color: #c995fc;
      color: #fff;
    }
  }
`;
export const InputSearch = styled.input`
  margin-left: 15px;
  width: 55%;
  max-height: 33px;
  border: solid 2px #d0d6f2;
  border-radius: 4px;
  background-color: #ebecf0;
  padding: 3px 26px 3px 10px;
  color: #3b3b3b;
`;
